html,
body {
    margin: 0;
    padding: 0;
    height: 100vh;
}
body {
    margin: 0;
    padding: 25px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background: #c33764;
    background: -webkit-linear-gradient(to right, #1d2671, #c33764);
    background: linear-gradient(to right, #1d2671, #c33764);
}
h1 {
    text-align: center;
}
.container {
    display: flex;
    flex-flow: row wrap;
    gap: 50px;
}
.container > section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}
.container input,
.container button {
    width: 250px;
}
.container pre {
    min-width: 300px;
    background-color: white;
    color: black;
    padding: 10px;
    white-space: pre-wrap;
    overflow-y: auto;
    margin: 0;
}
.container .leaflet-container,
.container pre {
    height: 60vh;
    width: 100%;
}
#input-container {
    display: flex;
    flex-flow: row wrap;
    gap: 25px;
    width: 100%;
}
#input-container > * {
    flex: 1;
}
.p-button-success {
    width: 100% !important;
}
